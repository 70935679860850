import { Button, Col, Form, FormInstance, Select, Space } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import {
  INetwork,
  IPlatformsArray,
  IPlatformsWithNetwork,
  IProjectsData,
} from '../../../types/commonInterfaces'
import { AuthContext } from '../../../context/AuthContext'
import { EPlatformLabelMap } from '../enums'
import { isDevelopmentEnvironmentServed } from '../../../util/environment'

const { Option } = Select

const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    text-transform: capitalize;
  }
`
export const TEST_ORG = 'Gateway Free Trial'

interface IProps {
  form: FormInstance
  projectPlatforms: IPlatformsWithNetwork
  projectType: string
  isEdit: boolean
  projectData: IProjectsData
}

type ChosenPlatform = {
  platform: {
    value: string
    label: string
  }
  network: string
}

export const PlatformChooseProjectCreation: FC<IProps> = ({
  form,
  projectPlatforms,
  projectType,
  isEdit,
  projectData,
}) => {
  const { region } = useContext(AuthContext)

  const appropriateNetworks = (index: number): INetwork[] => {
    const platformName =
      form.getFieldValue('platformsArray') &&
      form.getFieldValue('platformsArray')[index] &&
      form.getFieldValue('platformsArray')[index].platform.value
    if (platformName && projectPlatforms[platformName]?.network) {
      const platformNetwork = projectPlatforms[platformName].network
      const isGnosis = platformName === 'gnosis'
      const isDevEnv = isDevelopmentEnvironmentServed()
      const isEuCentral2 = region === 'eu-central-2'
      const isEuCentral1 = region === 'eu-central-8'

      if (isGnosis) {
        if (isEuCentral2 || (isDevEnv && isEuCentral1)) {
          return platformNetwork
        }
        return [platformNetwork[0]]
      }

      return platformNetwork
    }
    return []
  }

  const shouldAddButtonBeDisabled = () => {
    const availablePlatforms = Object.keys(projectPlatforms)
    const chosenPlatforms = form.getFieldValue('platformsArray')

    // Check if there is any platform in availablePlatforms that is not in chosenPlatforms
    const hasMissingPlatform = availablePlatforms.some((platform) => {
      const found = chosenPlatforms.some(
        (chosenPlatform: ChosenPlatform) =>
          chosenPlatform?.platform?.value === platform
      )
      return !found
    })

    return !hasMissingPlatform
  }

  const availablePlatforms = useCallback(() => {
    const chosenPlatforms = form.getFieldValue('platformsArray')

    return Object.keys(projectPlatforms)
      .filter((platformName) => {
        if (chosenPlatforms?.length) {
          return !chosenPlatforms.some(
            (chosenItem: {
              platform: {
                label: string
                value: string
                key: string
              }
              network: string
            }) => {
              return chosenItem?.platform?.value === platformName
            }
          )
        }

        return true
      })
      .map((platformName) => ({
        value: platformName,
        label: projectPlatforms[platformName].platFormLabel,
      }))
  }, [form.getFieldValue('platformsArray'), projectPlatforms, projectType])

  const listInitialValueGetter = useMemo(() => {
    if (!isEdit) {
      return [undefined]
    }
    return projectData.ProjectPlatforms.map(
      ({ PlatformName, ProjectPlatformNetworks }) => {
        return {
          platform: { value: PlatformName, label: EPlatformLabelMap[PlatformName] },
          network: ProjectPlatformNetworks && ProjectPlatformNetworks[0].Network,
        }
      }
    )
  }, [
    isEdit,
    projectData?.ProjectPlatforms,
    projectData?.ProjectPlatforms[0]?.ProjectPlatformNetworks,
  ])
  const shouldSelectBeUpdated = (
    cur: { platformsArray: IPlatformsArray },
    prev: { platformsArray: IPlatformsArray }
  ) => cur.platformsArray !== prev.platformsArray

  const isSelectDisabled = (index: number) => {
    if (!isEdit) {
      return false
    }
    return index < projectData.ProjectPlatforms?.length
  }

  const onSelectChange = (index: number) => {
    const platformsArray = form.getFieldValue('platformsArray')
    if (platformsArray[index]?.network) {
      platformsArray[index].network = undefined
      form.setFieldsValue({ platformsArray })
    }
  }
  return (
    <Form.Item noStyle>
      <Form.List name="platformsArray" initialValue={listInitialValueGetter}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.key}
                label={`Platform ${index + 1}`}
                style={{ height: 30 }}
              >
                <Space align="baseline">
                  <Form.Item noStyle shouldUpdate={shouldSelectBeUpdated}>
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, 'platform']}
                        rules={[{ required: true, message: 'Choose platform' }]}
                      >
                        <SelectStyled
                          style={{ width: 170 }}
                          labelInValue
                          disabled={isSelectDisabled(index)}
                          onChange={() => onSelectChange(index)}
                        >
                          {availablePlatforms().map((item) => (
                            <Option
                              key={item.value}
                              value={item.value}
                              style={{ textTransform: 'capitalize' }}
                              data-testid={`platform_name_${item.value}`}
                            >
                              {item.label}
                            </Option>
                          ))}
                        </SelectStyled>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={shouldSelectBeUpdated}>
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, 'network']}
                        rules={[{ required: true, message: 'Choose network' }]}
                      >
                        <Select
                          style={{ width: 170, textTransform: 'capitalize' }}
                          disabled={isSelectDisabled(index)}
                        >
                          {appropriateNetworks(index).map((item) => (
                            <Option
                              value={item.url}
                              key={item.url}
                              data-testid={`platform_network_${item.label}`}
                            >
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                  {form.getFieldValue('platformsArray')?.length > 1 &&
                    !isSelectDisabled(index) && (
                      <DeleteOutlined
                        onClick={() => remove(field.name)}
                        disabled={isSelectDisabled(index)}
                        style={{ color: '#8C8C8C' }}
                      />
                    )}
                </Space>
              </Form.Item>
            ))}
            <Form.Item noStyle shouldUpdate={shouldSelectBeUpdated}>
              {() => (
                <Form.Item label={' '} colon={false}>
                  <Col span={10}>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      disabled={shouldAddButtonBeDisabled()}
                      style={{ width: 170 }}
                    >
                      Add new platform
                    </Button>
                  </Col>
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
